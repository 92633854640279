export const MenuItems = [
  {
    title: "Home",
    url: "#",
    cName: "nav-links",
  },
  {
    title: "About",
    url: "#",
    cName: "nav-links",
  },
  {
    title: "Contact",
    url: "#",
    cName: "nav-links",
  },
  {
    title: "Blog",
    url: "#",
    cName: "nav-links",
  },
  {
    title: "Careers",
    url: "#",
    cName: "nav-links",
  },
];
